import { find, get, isEqual } from "lodash-es";

/** Returns a copy of the updated array */
export const updateCollection = function <T>(items: T[], itemUpdated: T, path = "id") {
  return items.map((item) => (get(item, path) === get(itemUpdated, path) ? itemUpdated : item));
};

/** Returns a copy of the updated array */
export const updateCollectionFromList = function <T>(items: T[], itemsUpdated: T[], path = "id") {
  return itemsUpdated.reduce((previousValue, itemUpdated) => updateCollection(previousValue, itemUpdated, path), items);
};

export const detectChangesInCollection = function <T>(newCollection: T[], oldCollection: T[], path = "id") {
  const insertedItems = newCollection.filter((item) => !isin(oldCollection, item, path));
  const removedItems = oldCollection.filter((item) => !isin(newCollection, item, path));

  const remainingItems = newCollection.filter((item) => isin(oldCollection, item, path));

  const updatedItems = remainingItems.filter((item) => !isEqual(item, find(oldCollection, path)));

  return {
    insertedItems,
    removedItems,
    updatedItems,
  };
};
